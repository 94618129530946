<template>
  <div class="step-form-style-desc">
    <a-form layout="inline" style="max-width: 550px; margin: 40px auto 0" >
      <a-row style="width:100%;">
        <a-col :span="16">
          <a-row style="margin-bottom: 5px;">
            <a-col :span="11">
              <a-input :placeholder="$t('route.FirstName')" v-model:value="firstnameInput" @pressEnter="onClickSearchByCustomer" allow-clear/>
            </a-col>
            <a-col :span="11" :offset="1">
              <a-input :placeholder="$t('route.LastName')" v-model:value="lastnameInput" @pressEnter="onClickSearchByCustomer" allow-clear/>
            </a-col>
          </a-row>
          <a-row :wrapperCol="{ span: 16 }">
            <a-col :span="11">
              <a-input :placeholder="$t('route.Email')" v-model:value="emailInput" @pressEnter="onClickSearchByCustomer" allow-clear/>
            </a-col>
            <a-col :span="11" :offset="1">
              <a-input :placeholder="$t('route.Phone')" v-model:value="phoneInput" @pressEnter="onClickSearchByCustomer" allow-clear/>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="6">
          <a-form-item>
            <a-button
              type="primary"
              @click="onClickSearchByCustomer"
              style="margin-bottom: 5px;"
              :loading="loadingSearchCustomer"
            >{{ $t('route.Search') }}</a-button>
            <a-button
              key="blankRMA"
              type="primary"
              @click="onClickCreateBlankRMA"
              v-if="showBlankRmaButton"
            >
              <template #icon>
                <PlusOutlined />
              </template>
              {{ $t('route.blankRMA') }}
            </a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <a-modal
      v-model:visible="searchCustomerModal2Visible"
      centered
      @ok="onClickSelectCustomer"
      width="100%"
      wrapClassName="full-modal"
      :maskClosable="false"
      :loading="loadingSearchCustomerDetails"
    >
      <template #footer>
        <a-button key="blankRMA" type="primary" @click="onClickCreateBlankRMA" style="float:left;">
          <template #icon>
            <PlusOutlined />
          </template>
          {{ $t('route.blankRMA') }}
        </a-button>
        <a-button key="back" @click="onClickCancel">{{ $t('permission.cancel') }}</a-button>
        <a-button key="submit" type="primary" @click="onClickSelectCustomer" :loading="loadingSearchCustomerDetails">{{ $t('permission.submit') }}</a-button>
      </template>
      <a-layout>
        <a-layout-content class="customerlist">
          <a-table
            :data-source="customers.array" 
            :columns="searchCustomerModalColumns"
            :row-selection="customerRowSelection"
            :pagination="false"
            :customRow="customRow"
          >
            <template #name="{ text }">
              <a>{{ text }}</a>
            </template>
          </a-table>
        </a-layout-content>
      </a-layout>
    </a-modal> 
        
    <a-modal
      v-model:visible="searchOrderDetailsModal2Visible"
      width="100%"
      wrapClassName="full-modal"
      :maskClosable="false"
      :closable="false"
    >
      <template #footer>
        <a-button danger @click="resetAll()" v-if="showResetAll" style="float:left;">{{ $t('listRMA.resetAll') }}</a-button>
        <a-button key="back" @click="onClickCancel">{{ $t("permission.cancel") }}</a-button>
        <a-button
          key="submit"
          type="primary"
          @click="handleOk"
        >{{ $t("permission.submit") }}</a-button>
      </template>
      <a-table
        :columns="searchOrderDetailsModalcolumns"
        :data-source="orderDetails.array"
        :row-selection="productsRowSelection"
        :custom-row="customRow"
        :pagination="pagination"
        @change="handleChange"
      >
        <template #img="{ record }">
          <div>
            <a-image width="50px" :src="record.avatar" :alt="record.sav" />
          </div>
        </template>
        <template #extendedWarranty="{ record }">
          <div>{{ record.extended_warranty == 1 ? 'Yes' : 'No' }}</div>
        </template>
        <template #filterIcon="{ filtered }">
          <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>
        <template
          #filterDropdown="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
        >
          <div style="padding: 8px">
            <a-input
              ref="searchInput"
              :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex, clearFilters)"
            />
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="handleSearch(selectedKeys, confirm, column.dataIndex, clearFilters)"
            >
              <template #icon><SearchOutlined /></template>
              {{ $t('route.Search') }}
            </a-button>
            <a-button
              size="small"
              style="width: 90px"
              @click="handleReset(clearFilters, column.dataIndex)"
            >
              {{ $t('listRMA.reset') }}
            </a-button>
          </div>
        </template>
        <template #repairTimes="{ record }">
          <div class="repair_times_box">
            <div>{{ record.repair_history.total_180 }}</div>
            <a-button type="link" :disabled="record.repair_history.total_180 <= 0" @click="viewRepairHistory(record.repair_history.id)">{{ $t('route.viewBtn') }}</a-button>
          </div>
        </template>
      </a-table>
    </a-modal>

    <RepairTimes ref='repair_times'></RepairTimes>
  </div>
</template>

<script>
  import { computed, reactive, ref } from '@vue/reactivity'
  import { notification } from 'ant-design-vue';
  import useClientRepositories from '@/composables/useClientRepositories'
  import { PlusOutlined, SearchOutlined } from '@ant-design/icons-vue';
  import { useI18n } from 'vue-i18n';
  import { formatDate } from '@/utils/utils';
  import RepairTimes from '@/components/repairTimes'
  import RepairTimesState from '@/state/RepairTimes'

  const searchInvoiceColumns = [
    {
      title: 'Image',
      key: 'img',
      slots: {
        customRender: 'img',
      },
    },
    {
      title: 'Date',
      dataIndex: 'dateDisplay',
      key: 'dateDisplay',
    },
    {
      title: 'Warranty',
      dataIndex: 'warranty',
      key: 'warranty',
    },
    {
      title: 'ItemNo',
      dataIndex: 'itemno',
      key: 'itemno',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
  ]

  export default {
    data() {
      return {
        searchInvoiceColumns
      }
    },
    emits: ['stepCreateBlankRMA', 'stepCreateRMA'],
    components: {
      PlusOutlined,
      SearchOutlined,
      RepairTimes
    },
    setup() {
      const { searchInvoiceByCustomer, searchCustomerDetails } = useClientRepositories()
      const { viewRepairHistory } = RepairTimesState();
      const customers = reactive({ array: [] })
      const orderDetails = reactive({ array: [] })
      const firstnameInput = ref('')
      const lastnameInput = ref('')
      const phoneInput = ref('')
      const emailInput = ref('')
      const loadingSearchCustomer = ref(false)
      const loadingSearchCustomerDetails = ref(false)
      const hideCustomerPagination = ref(true)
      const searchCustomerModal2Visible = ref(false)
      const searchOrderDetailsModal2Visible = ref(false)
      const showBlankRmaButton = ref(false)
      const { t ,locale } = useI18n({ useScope: 'global' })
      const clearFiltersFunction = ref({})
      const tableFilters = ref({})
      const tableSorter = ref({})
      const isResetAll = ref(false);
      const repair_times = ref();
      const state = reactive({
        selectedRowKeys: [],
        productsRowSelected: []
      });
      var informationNextStep = reactive({
        customerInfo: {},
        product: []
      })

      const searchCustomerModalColumns = computed(() => {
        return [
          {
            title: t('route.Name'),
            dataIndex: 'fullname',
            key: 'fullname',
            width: 110,
          },
          {
            title: t('route.Email'),
            dataIndex: 'emailaddress',
            key: 'emailaddress',
            width: 170,
          },
          {
            title: t('route.Phone'),
            dataIndex: 'fullphone',
            key: 'fullphone',
            width: 130,
          },
        ]
      })

      const current = ref(1);
      const pagination = computed(() => {
        return ({
          current: current.value,
          showQuickJumper: true
        })
      })
      const searchOrderDetailsModalcolumns = computed(() => {
        const filtered = tableFilters.value || {};
        const sorted = tableSorter.value || {};
        return [
          {
            title: t('route.Image'),
            key: 'img',
            align: 'center',
            slots: {
              customRender: 'img',
            },
          },
          {
            title: t('route.Date'),
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            slots: {
              filterDropdown: "filterDropdown",
              filterIcon: "filterIcon"
            },
            onFilter: (value, record) => record.date.toString().toLowerCase().includes(value.toLowerCase()),
            sorter: (a, b) => {
              return a.date.localeCompare(b.date)
            },
            sortOrder: sorted.columnKey === 'date' && sorted.order,
          },
          {
            title: t('route.WarrantyDate'),
            dataIndex: 'expiry_date',
            key: 'expiry_date',
            align: 'center',
            slots: {
              filterDropdown: "filterDropdown",
              filterIcon: "filterIcon"
            },
            onFilter: (value, record) => record.expiry_date.toString().toLowerCase().includes(value.toLowerCase()),
            sorter: (a, b) => {
              return a.expiry_date.localeCompare(b.expiry_date)
            },
          },
          {
            title: t('route.Warranty'),
            dataIndex: 'warranty',
            key: 'warranty',
            align: 'center',
            filters: [
              { text: 'Under Warranty', value: 'Under Warranty' },
              { text: 'Expired', value: 'Expired' }
            ],
            filteredValue: filtered.warranty || null,
            onFilter: (value, record) => record.warranty.toString() == value
          },
          {
            title: t('route.ExtendedWarranty'),
            dataIndex: 'extended_warranty',
            key: 'extended_warranty',
            align: 'center',
            slots: {
              customRender: "extendedWarranty"
            },
            filters: [
              { text: 'Yes', value: 1 },
              { text: 'No', value: 0 }
            ],
            filteredValue: filtered.extended_warranty || null,
            onFilter: (value, record) => record.extended_warranty.toString() == value
          },
          {
            title: t('route.Item'),
            dataIndex: 'itemno',
            key: 'itemno',
            align: 'center',
            slots: {
              filterDropdown: "filterDropdown",
              filterIcon: "filterIcon"
            },
            onFilter: (value, record) => record.itemno.toString().toLowerCase().includes(value.toLowerCase())
          },
          {
            title: t('route.Size'),
            dataIndex: 'size',
            key: 'size',
            align: 'center',
          },
          {
            title: t('route.Amount'),
            dataIndex: 'amount',
            key: 'amount',
            align: 'center',
          },
          {
            title: t('route.Quantity'),
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center',
          },
          {
            title: t('route.InvoiceNo'),
            dataIndex: 'order_id',
            key: 'order_id',
            align: 'center',
            slots: {
              filterDropdown: "filterDropdown",
              filterIcon: "filterIcon"
            },
            onFilter: (value, record) => record.order_id.toString().toLowerCase().includes(value.toLowerCase())
          },
          {
            title: t('route.RepairTime'),
            dataIndex: 'repair_history.total_180',
            key: 'repair_history.total_180',
            align: 'center',
            slots: {
              customRender: "repairTimes"
            },
          },
        ]
      })

      const showResetAll = computed(() => {
        let num = 0
        for(const i in tableFilters.value) {
          if (tableFilters.value[i] != null && tableFilters.value[i] != '') num++
        }
        if (tableSorter.value.order) num++
        return num > 1
      })

      const handleChange = (pagination, filters, sorter) => {
        if (isResetAll.value) return
        tableFilters.value = filters;
        tableSorter.value = sorter;
        current.value = pagination.current;
      };
      const handleSearch = (selectedKeys, confirm, dataIndex, clearFilters) => {
        confirm();
        clearFiltersFunction.value[dataIndex] = clearFilters
      };
      const handleReset = (clearFilters) => {
        clearFilters();
        delete clearFiltersFunction.value[dataIndex]
      };

      const resetAll = () => {
        current.value = 1;
        tableFilters.value = {};
        tableSorter.value = {};
        for(const i in clearFiltersFunction.value) {
          clearFiltersFunction.value[i]()
        }
        clearFiltersFunction.value = {}
        isResetAll.value = true
        setTimeout(() => {
          isResetAll.value = false
        }, 500)
      };

      const customerRowSelection = computed(() => {
        return {
          selectedRowKeys: state.selectedRowKeys,
          type:	'radio',
          onChange: (selectedRowKeys) => {
            state.selectedRowKeys = selectedRowKeys;
          },
        };
      });

      const onClickCancel = () => {
        customers.array = []
        state.productsRowSelected = []
        state.selectedRowKeys = []
        informationNextStep.product = []
        informationNextStep.customerInfo = {}
        // CustomersearchCustomerModal2Visible = false
        searchCustomerModal2Visible.value = false
        searchOrderDetailsModal2Visible.value = false
      }

      const onClickSearchByCustomer = async () => {
        loadingSearchCustomer.value = true
        customers.array = []

        showBlankRmaButton.value = false
        try {
          customers.array = await searchCustomerDetails(firstnameInput.value, lastnameInput.value, emailInput.value, phoneInput.value)
        } catch (e) {
          notification.error({
            message: t("route.NoMatchesFound"),
            description: ""
          })
          showBlankRmaButton.value = true
          loadingSearchCustomer.value = false
          return
        }
        
        if(customers.array.length <= 10) {
          hideCustomerPagination.value = true
        } else {
          hideCustomerPagination.value = false
        }
        searchCustomerModal2Visible.value = true

        loadingSearchCustomer.value = false
      }

      const onClickSelectCustomer = async () => {
        loadingSearchCustomerDetails.value = true
        current.value = 1
        if (state.selectedRowKeys.length <= 0) {
          notification.error({
            message: 'Please select one customer',
            description: ""
          })
          loadingSearchCustomerDetails.value = false
        } else {

          const k = state.selectedRowKeys[0]
          var customerInfo = {firstname: "", lastname: "", emailaddress1: "", new_apm_phoneareacode: "", mobilephone: "", origin_invoice_number: "", dateOfPurchase: "", underWarranty: "" }
          // console.log(customers.array[k], "customers.array[k]")
          customerInfo.firstname = customers.array[k].firstname
          customerInfo.lastname = customers.array[k].lastname
          customerInfo.emailaddress1 = customers.array[k].emailaddress
          customerInfo.new_apm_phoneareacode = customers.array[k].new_apm_phoneareacode
          customerInfo.mobilephone = customers.array[k].mobilephone
          customerInfo.origin_invoice_number = ""
          customerInfo.dateOfPurchase = ""
          customerInfo.underWarranty = ""
          customerInfo.crm_customer_id = customers.array[k].contactid
          informationNextStep.customerInfo.data = []
    
          informationNextStep.customerInfo.data = customerInfo
          
          orderDetails.array = []
          try {
            orderDetails.array = await searchInvoiceByCustomer(customers.array[k].contactid)
            // console.log("orderDetails.array")
            // console.log(orderDetails)
          } catch (e) {
            console.log(e)
            notification.error({
              message: 'Network Error',
              description: ""
            })
            loadingSearchCustomerDetails.value = false  
            return
          } 

          loadingSearchCustomerDetails.value = false
          searchCustomerModal2Visible.value = false
          searchOrderDetailsModal2Visible.value = true 

        }
      }

      const productsRowSelection = computed(() => {
        return {
          onSelect: (record, selected) => {
            if (selected) {
              record.selected = true
              state.productsRowSelected.push(record)
            } else {
              record.selected = false
              state.productsRowSelected = state.productsRowSelected.filter(function (ele) {
                return ele.itemno != record.itemno
              })
            }
          },
          onSelectAll: (selected, selectedRows, changeRows) => {
            state.productsRowSelected = []
            selectedRows.forEach(v => {
              v.selected = selected
              if (selected) {
                state.productsRowSelected.push(v)
              }
            })
          },
          getCheckboxProps: record => ({
            disabled: record.itemno.includes('ACCESS') || record.itemno.includes('DISPLAY'),
            // Column configuration not to be checked
            // name: record.name,
          }),
        };
      });

      const customRow = (record) => {
        return {
          onClick: () => {
            selectRow(record);
          },
        };
      };

      const selectRow = (record) => {
        let selectedRowKeys = [...state.selectedRowKeys];
      
        selectedRowKeys = []
        if (selectedRowKeys.indexOf(record.key) >= 0) {
          selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
          selectedRowKeys.push(record.key);
        }
        state.selectedRowKeys = selectedRowKeys;
      };

      return { customers, orderDetails, firstnameInput, lastnameInput, phoneInput, emailInput, loadingSearchCustomer, onClickSearchByCustomer, 
               searchCustomerModal2Visible, searchOrderDetailsModal2Visible, customerRowSelection, onClickSelectCustomer, productsRowSelection, customRow, state, informationNextStep,
               loadingSearchCustomerDetails, hideCustomerPagination, onClickCancel, t, locale, searchCustomerModalColumns, searchOrderDetailsModalcolumns, showBlankRmaButton,
               pagination, current, handleChange, handleSearch, handleReset, resetAll, clearFiltersFunction, tableFilters, tableSorter, isResetAll, showResetAll,
               viewRepairHistory, repair_times }
    },
    methods: {
      onClickCreateBlankRMA() {
        this.$emit('stepCreateBlankRMA', 1)
      },
      handleOk() {
        for (var i = 0; i < this.state.productsRowSelected.length; i++) {
          this.informationNextStep.product.push(this.state.productsRowSelected[i])
        }
        // console.log("handleOK")
        // console.log(this.informationNextStep.product)
        if (this.state.productsRowSelected.length > 0) {
          this.$emit("stepCreateRMA", this.informationNextStep);
          this.searchOrderDetailsModal2Visible = !this.searchOrderDetailsModal2Visible
        //   this.informationNextStep
        } else {
          notification.error({
            message: 'Please Select a product',
            description: ""
          })
        }
      },
    }
  }
</script>
<style lang="scss">

// .ant-modal {
//     max-width: 100%;
//     max-height: 100%;
//     top: 0;
//     padding-bottom: 0;
//     margin: 0;
//   }
//   .ant-modal-content {
//     display: flex;
//     flex-direction: column;
    
//   }
//   .ant-modal-body {
//     flex: 1;
//     padding: 0;
//   }
  
//   .ant-modal-footer {
//     position: fixed;
//     left: 0;
//     bottom: 0;
//     width: 100%;
//     background-color: 'white';
//   }

.full-modal {
  .ant-modal-content {
    height: auto;
    min-height: calc(100vh);
  }
}

.ant-table-pagination.ant-pagination {
  float: right;
  margin: 8px 0 60px;
}
// .ant-table {
//   padding-bottom: 25px;
// }
.customerlist thead th { 
  position: sticky; 
  top: 0; 
  z-index:99;
}

.ant-form-item-control {
  line-height: normal !important;
}

.repair_times_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.RepairHistory_table {
  padding-top: 32px;

  .reason_box {
    display: flex;
    flex-wrap: wrap;

    .reason {
      margin-right: 10px;
    }
  }
}

</style>